import { Component, OnInit } from "@angular/core";
import { Options } from "ng5-slider";
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from "@angular/fire/firestore";
import { Observable, pipe } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-product",
  templateUrl: "./product.component.html",
  styleUrls: ["./product.component.scss"],
})
export class ProductComponent implements OnInit {
  golds: Observable<any[]>;
  silvers: Observable<any[]>;
  constructor(firestore: AngularFirestore) {
    this.golds = firestore.collection("gold").valueChanges();
    this.silvers = firestore.collection("silver").valueChanges();
  }

  ngOnInit() {}

  probs = [
    {
      id: 1,
      name: "333",
    },
    { id: 2, name: "585" },
    { id: 3, name: "750" },
  ];

  public selectedProb: any = {
    id: 1,
    name: "333",
  };

  public stanSelect: any = false;

  public stoneSel = "Nie";

  stones: string[] = ["Tak", "Nie"];

  value = 0;

  value_silver = 0;

  options: Options = {
    floor: 0,
    ceil: 30,
  };

  public AcvtiveState = "GOLD";

  changeState(stateName) {
    this.AcvtiveState = stateName;
  }

  takePrice(gold) {
    if (this.stoneSel === "Tak") {
      if (this.stanSelect) {
        return (this.value * gold.withStone.zlomPrice).toFixed();
      } else {
        return (this.value * gold.withStone.price).toFixed();
      }
    } else {
      if (this.stanSelect) {
        return (this.value * gold.withoutStone.zlomPrice).toFixed();
      } else {
        return (this.value * gold.withoutStone.price).toFixed();
      }
    }
  }

  takePriceSilver(silver) {
    return (this.value_silver * silver.price).toFixed();
  }
}
