import { Component, OnInit } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { Observable } from "rxjs";
@Component({
  selector: "app-kantor",
  templateUrl: "./kantor.component.html",
  styleUrls: ["./kantor.component.scss"],
})
export class KantorComponent implements OnInit {
  kurs: Observable<any[]>;
  constructor(firestore: AngularFirestore) {
    this.kurs = firestore.collection("kurs").valueChanges();
  }

  renderImg = (name: string) => {
    switch (name) {
      case "EUR":
        return "../../../../../assets/images/table/european-union.svg";
      case "USD":
        return "../../../../../assets/images/table/united-states.svg";
      case "GBP":
        return "../../../../../assets/images/table/united-kingdom.svg";
      case "CHF":
        return "../../../../../assets/images/table/switzerland.svg";
      case "CAD":
        return "../../../../../assets/images/table/denmark.svg";
      case "NOK":
        return "../../../../../assets/images/table/norway.svg";
      case "SEK":
        return "../../../../../assets/images/table/sweden.svg";
      case "CZK":
        return "../../../../../assets/images/table/czech-republic.svg";
      case "HUF":
        return "../../../../../assets/images/table/hungary.svg";
      case "UAH":
        return "../../../../../assets/images/table/ukraine.svg";
      case "DKK":
        return "../../../../../assets/images/table/denmark.svg";
      default:
        return;
    }
  };

  date = "24.01.2020";

  curs = [
    {
      value: "EUR",
      img: "../../../../../assets/images/table/european-union.svg",
      kupno: "4.250",
      spredaz: "4.270",
    },
    {
      value: "USD",
      img: "../../../../../assets/images/table/united-states.svg",
      kupno: "3.850",
      spredaz: "3.870",
    },
    {
      value: "GBP",
      img: "../../../../../assets/images/table/united-kingdom.svg",
      kupno: "4.930",
      spredaz: "4.960",
    },
    {
      value: "CHF",
      img: "../../../../../assets/images/table/switzerland.svg",
      kupno: "3.840",
      spredaz: "3.920",
    },
    {
      value: "CAD",
      img: "../../../../../assets/images/table/canada.svg",
      kupno: "2.890",
      spredaz: "2.930",
    },
    {
      value: "DKK",
      img: "../../../../../assets/images/table/denmark.svg",
      kupno: "0.575",
      spredaz: "0.590",
    },
    {
      value: "NOK",
      img: "../../../../../assets/images/table/norway.svg",
      kupno: "0.433",
      spredaz: "0.441",
    },
    {
      value: "SEK",
      img: "../../../../../assets/images/table/sweden.svg",
      kupno: "0.401",
      spredaz: "0.408",
    },
    {
      value: "CZK",
      img: "../../../../../assets/images/table/czech-republic.svg",
      kupno: "0.163",
      spredaz: "0.169",
    },
    {
      value: "HUF",
      img: "../../../../../assets/images/table/hungary.svg",
      kupno: "0.0131",
      spredaz: "0.0136",
    },
    {
      value: "UAH",
      img: "../../../../../assets/images/table/ukraine.svg",
      kupno: "0.0155",
      spredaz: "0.0165",
    },
  ];

  ngOnInit() {}
}
