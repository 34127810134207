import { Component, OnInit } from "@angular/core";

/**
 * Hamburgers to use
 * https://github.com/jonsuh/hamburgers
 */

@Component({
  selector: "app-header-hamburger",
  templateUrl: "./hamburger.component.html",
  styleUrls: ["./hamburger.component.scss"]
})
export class HamburgerComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
